/**
 * Copyright © 2019 Johnson & Johnson
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import { Helmet } from "react-helmet";
import Page from "./Page";
import Gtm from "src/components/GTM";

export default class IndexPage extends React.Component {
    render() {
        if (typeof window !== "undefined") {
            return (
                <div
                    dangerouslySetInnerHTML={{
                        __html: "https://github.com/facebook/react/issues/10923"
                    }}
                />
            );
        }
        return (
            <React.Fragment>
                <Page />
                <Helmet>
                    <html lang="sk" />
                    <body className="home" />

                    <meta charSet="utf-8" />
                    <title>404 - Stránka nenájdená</title>
                    <link rel="shortcut icon" href="/favicon.ico" />
                    <meta
                        content="Potrebujete rýchlu úľavu pri nádche? Označte príznaky a vyberte príslušný liek OLYNTH® alebo OlynHexo™ na nádchu a bolesť v krku."
                        name="description"
                    />
                    <meta content="text/html; charset=utf-8" httpEquiv="Content-Type" />
                    <meta content name="keywords" />
                    <meta name="robots" content="index, follow" />
                    <link
                        rel="stylesheet"
                        type="text/css"
                        media="screen"
                        href="/css/reset.css"
                    />
                    <link
                        rel="stylesheet"
                        type="text/css"
                        media="screen"
                        href="/css/font-awesome.min.css"
                    />
                    <link
                        rel="stylesheet"
                        type="text/css"
                        media="screen"
                        href="/css/jquery-ui.css"
                    />
                    <link
                        rel="stylesheet"
                        type="text/css"
                        media="screen"
                        href="/css/style.css?t=20181203"
                    />
                    <script type="text/javascript" src="/js/jquery-3.5.1.min.js" />
                    <script type="text/javascript" src="/js/jquery-migrate-3.3.0.js" />
                    <script type="text/javascript" src="/js/jquery-ui.min.js" />
                    <script type="text/javascript" src="/js/jquery.mixitup.js" />
                    <script
                        type="text/javascript"
                        src="/js/jquery.selectbox-0.2.min.js"
                    />
                    <script type="text/javascript" src="/js/cookie/jquery.cookie.js" />
                    <script type="text/javascript" src="/js/scripts.js" />
                    <meta
                        property="og:image"
                        content="/uploads/fb_thumb.jpg"
                    />
                    <script
                        src="//tt.mbww.com/tt-f0e3bd92f157f9b73ede82834286e7cea4044134b39d92ac3ee7e56392194241.js"
                        async
                    />
                    <meta name="viewport" content="initial-scale=1" />
                </Helmet>
                <Gtm />
        ;
            </React.Fragment>
        );
    }
}
