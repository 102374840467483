import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
    render() {
        return (
            <div>
                <header>
                    <div className="page_width rel">
                        <nav>
                            <a href="/" id="logo" />
                            <span id="menu_btn" />
                            <ul id="menu">
                                <li>
                                    <a href="/">
                                        Hlavná stránka
                  </a>
                                </li>
                                <li>
                                    <a href="/lieky/">Lieky</a>
                                </li>
                                <li>
                                    <a href="/radca/">Radca</a>
                                </li>
                                <li>
                                    <a href="/upravte_liek/">Test</a>
                                </li>
                                <li>
                                    <a href="/kontakt/">Kontakt</a>
                                </li>
                                <li>
                                    <a href="/otazky_a_odpovede/">Otázky a odpovede</a>
                                </li>
                                <li>
                                    <a href="/kde_kupit/">KDE KÚPIŤ </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </header>
                <div id="page" className="page_width">
                    <div id="page_content">
                        <h1>Stránka nenájdená</h1>
                        <p>Táto stránka sa nenašla</p>
                        <p style={{ marginTop: "4px" }}>Na domovskú stránku sa dostanete kliknutím <a href="/">Hlavná stránka</a></p>
                    </div>{" "}
                </div>
                <Footer></Footer>{" "}
            </div>
        );
    }
}

export default Page;
